import { processTopNav } from "./processTopNav.js";
import type { DefinedTopNavConfig, TopNavConfig } from "./types.js";

export function defineTopNav(topNavDefinition: DefinedTopNavConfig): TopNavConfig {
  return Object.fromEntries(
    Object.entries(topNavDefinition)
      .map(([k, v]) => [k, processTopNav(v, k)])
      .filter(([, v]) => Object.keys(v).length > 0)
  );
}
