<script lang="ts">
let instanceCounter = 0;
</script>
<script setup lang="ts">
import { inject, provide, ref } from "vue";
import { accordionGroupKey, accordionKey } from "./injectionKeys.js";

const props = withDefaults(
  defineProps<{
    open?: boolean;
    noBorder?: boolean;
  }>(),
  {
    open: false,
  }
);

const accordionID = `accordion-${++instanceCounter}`;
const contentID = `accordion-${accordionID}-content`;
const isExpanded = ref(props.open);

const { isInGroup, registerAccordion } = inject(accordionGroupKey, {});

if (isInGroup) {
  registerAccordion?.(isExpanded);
}

provide(accordionKey, { isExpanded, accordionID, contentID });
</script>

<template>
  <div class="accordion">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.accordion {
  margin-right: 2px; // leave some space for focus outline

  & + .accordion {
    border-top: 1px solid gfs.$pl_color-grey100;
  }
}
</style>
