<script lang="ts" setup>
import { computed, nextTick, ref, watch } from "vue";
import Icon from "../pattern/Icon/Icon.vue";
import SearchResultList from "./SearchResultList.vue";
import { useMagicKeys } from "@vueuse/core";
import { useSearch } from "../../use/search/index.js";
import { useRouter } from "vue-router";
import ClearIcon from "./ClearIcon.vue";

const SHORTCUT_LABELS = {
  MAC: "(⌘ + K)",
  OTHER: "(CTRL + K)",
};

const props = withDefaults(
  defineProps<{
    hasFocus: boolean;
    hasInput: boolean;
    placeholder?: string;
    isMobile: boolean;
    isMobileSearchClosed?: boolean;
    isSearchExpanded: boolean;
  }>(),
  {
    placeholder: "",
  }
);

const { results, input } = useSearch();
const inputfield = ref<HTMLInputElement>();

const isSearching = computed(() => input.value.length > 0);

const router = useRouter();

const emit = defineEmits(["closeSearch", "openSearch"]);

//Event Handler
const closeSearch = () => {
  emit("closeSearch");

  if (inputfield.value != undefined) {
    inputfield.value.blur();
  }
};

const openSearch = async () => {
  if (input.value.length > 1 && inputfield.value !== undefined) {
    const end = input.value.length;
    inputfield.value.setSelectionRange(end, end);
  }

  emit("openSearch");
  await nextTick();
  inputfield?.value?.focus();
};

//Custom Shortcut
const findInUserAgent = (needle: string): boolean => {
  return window.navigator.userAgent.toLowerCase().indexOf(needle) !== -1;
};

const setPlatformSpecificShortcut = () => {
  if (findInUserAgent("macintosh") && findInUserAgent("mac os x")) {
    return SHORTCUT_LABELS.MAC;
  } else {
    return SHORTCUT_LABELS.OTHER;
  }
};

const { escape, CTRL_K, META_K } = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (
      e.type === "keydown" &&
      (findInUserAgent("macintosh") ? e.metaKey : e.ctrlKey) &&
      e.key === "k"
    ) {
      e.preventDefault();
    }
  },
});

watch(escape, (v) => {
  if (v) {
    closeSearch();
  }
});

watch(CTRL_K, (v) => {
  if (props.hasFocus) {
    return;
  } else if (v) {
    input.value = "";
    openSearch();
  }
});

watch(META_K, (v) => {
  if (props.hasFocus) {
    return;
  } else if (v) {
    input.value = "";
    openSearch();
  }
});

//Arrow Key Navigation through SearchResultList
const selectedIndex = ref(-1);

watch(results, () => {
  selectedIndex.value = -1;
});

const onSelectDown = () => {
  if (selectedIndex.value >= results.value.length - 1) {
    selectedIndex.value = 0;
  } else {
    selectedIndex.value++;
  }
};

const onSelectUp = () => {
  if (selectedIndex.value > 0) {
    selectedIndex.value--;
  } else {
    selectedIndex.value = results.value.length - 1;
  }
};

const onSelectEnter = () => {
  const selectedItem = results.value[selectedIndex.value];

  if (selectedItem) {
    emit("closeSearch");

    if (inputfield.value != undefined) {
      inputfield.value.blur();
    }
    const href = selectedItem?.item?.link;

    router.push(href);
  }
};
</script>

<template v-if="hasInput">
  <div
    class="search-input"
    :class="[{ 'not-focused': !hasFocus }, { focused: hasInput && isSearching }]"
  >
    <div class="icon_search" @click="openSearch()">
      <Icon icon="pl_icon_search" size="100" />
    </div>
    <input
      ref="inputfield"
      class="search"
      type="text"
      :value="input"
      :class="{ isMobile: isMobile, isSearchingOnMobile: hasFocus && isMobile }"
      :placeholder="placeholder"
      @input="(event:any) => (input = event?.target?.value)"
      @keydown.prevent.up="onSelectUp"
      @keydown.prevent.down="onSelectDown"
      @keydown.prevent.enter="onSelectEnter"
      @click="openSearch()"
    />
    <!-- Search shortcut  -->
    <div
      v-if="!hasFocus && !isSearching && !isMobile"
      class="search-shortcut-container"
      @click="openSearch()"
    >
      <span class="search-shortcut-icon">{{ setPlatformSpecificShortcut() }}</span>
    </div>

    <div v-if="hasFocus && !isSearching" class="safe-area action-icon close-icon">
      <Icon data-close-icon icon="pl_icon_close" size="100" @click="closeSearch()" />
    </div>

    <div v-if="isSearching">
      <ClearIcon
        :is-search-expanded="isSearchExpanded"
        :inputfield="inputfield"
        :is-mobile="isMobile"
        @open-search="openSearch"
      />
    </div>

    <template v-if="hasInput && isSearching">
      <div class="search-result-container">
        <SearchResultList :selected-index="selectedIndex" @close-search="closeSearch()" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.search-input {
  position: relative;
  width: 100%;
  height: 48px;
  padding: gfs.$pl_padding50;
  border-radius: 24px;
  background-color: gfs.$pl_color-grey25;
  display: flex;
  gap: 4px;
  align-items: center;
  outline: transparent;
  z-index: 1;

  input {
    height: 32px;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: none;
    cursor: text;
    padding: 0;

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &.isMobile {
      display: none;
    }

    &.isSearchingOnMobile {
      display: block;
    }
  }
  .icon_search {
    padding: gfs.$pl_padding25;
    height: 32px;
    width: 32px;

    svg {
      fill: gfs.$pl_color-black100;
    }
  }

  &:focus {
    outline: none;
  }

  &.not-focused:hover {
    cursor: pointer;
    outline: 2px solid #d6d6d6;

    input {
      cursor: pointer;
    }
  }

  &.focused {
    border-radius: 1.5rem 1.5rem 0 0;
  }

  .action-icon {
    user-select: none;
    &.clear-icon svg {
      fill: gfs.$pl_color-grey400;
    }
  }

  .search-shortcut-container {
    margin-right: 1rem;

    .search-shortcut-icon {
      width: 80px;
      display: flex;
      color: #777777;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-direction: row;
    }
  }
}
</style>
