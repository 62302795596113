import { useTopNav } from "./useTopNav.js";

import { computed, ComputedRef } from "vue";
import { useSideNav } from "./useSideNav.js";
import type { LocationInternal } from "../../types/index.js";
import { isNavItem } from "../../navigation/index.js";
import { useRoute } from "../router/index.js";

export type BreadcrumbEntry = {
  title: string;
  link?: LocationInternal;
};

const useActiveTopNavEntries = () => {
  const { topNav, activeParentEntry, activeEntry } = useTopNav();

  return computed<BreadcrumbEntry[]>(() => {
    if (activeParentEntry.value) {
      const parent = topNav[activeParentEntry.value];

      if (parent && !isNavItem(parent)) {
        const entry = parent[activeEntry.value];
        return [
          {
            title: activeParentEntry.value,
          },
          {
            title: activeEntry.value,
            link: isNavItem(entry) ? entry.route : undefined,
          },
        ];
      }
    }

    const entry = topNav[activeEntry.value];
    return [
      {
        title: activeEntry.value,
        link: isNavItem(entry) ? entry.route : undefined,
      },
    ];
  });
};

const useActiveSideNavEntries = () => {
  const { sideNav, activeEntry } = useSideNav();

  return computed<BreadcrumbEntry[]>(() => {
    let current: any = sideNav.value;
    const res: BreadcrumbEntry[] = [];

    activeEntry.value.forEach((title) => {
      if (title in current) {
        current = current[title];

        res.push({
          title,
          link: isNavItem(current) ? current.route : undefined,
        });
      } else {
        current = {};
      }
    });

    return res;
  });
};

export function useBreadcrumb(): { breadcrumb: ComputedRef<BreadcrumbEntry[]> } {
  const { meta } = useRoute<
    unknown,
    {
      breadcrumb?: {
        title: string;
        hash: string;
      };
    }
  >();

  const home: BreadcrumbEntry = {
    title: "Home",
    link: {
      path: "/",
    },
  };

  const activeTopNavEntries = useActiveTopNavEntries();
  const activeSideNavEntries = useActiveSideNavEntries();

  const breadcrumb = computed(() => {
    const breadcrumbEntries = [home, ...activeTopNavEntries.value, ...activeSideNavEntries.value];
    breadcrumbEntries.pop();

    if (meta.value.breadcrumb) {
      const last = breadcrumbEntries[breadcrumbEntries.length - 1];
      const link = last.link ? { ...last.link, hash: meta.value.breadcrumb.hash } : undefined;

      if (last.title !== meta.value.breadcrumb.title) {
        // hack for https://github.com/otto-ec/ottoapi_portal/issues/1181
        breadcrumbEntries.push({ title: meta.value.breadcrumb.title, link });
      }
    }

    return breadcrumbEntries;
  });

  return {
    breadcrumb,
  };
}
