<script lang="ts" setup>
import NavigationItem from "./NavigationItem.vue";
import { useSideNav } from "../../use/index.js";
import { RouteRecordRaw } from "vue-router";
import { NestedNavItem } from "../../navigation/index.js";
import Accordion from "../Accordion/Accordion.vue";
import AccordionHeader from "../Accordion/AccordionHeader.vue";
import AccordionContent from "../Accordion/AccordionContent.vue";

const { sideNav, activeEntry } = useSideNav();

const emit = defineEmits(["closeDrawer"]);

const closeDrawer = () => {
  emit("closeDrawer");
};
</script>

<template>
  <div v-if="sideNav" ref="parent" class="navigation">
    <template v-for="(items, section) in sideNav" :key="section">
      <Accordion>
        <AccordionHeader>
          <div class="navigation__header pl_copy75">{{ section }}</div>
        </AccordionHeader>
        <AccordionContent>
          <template v-for="(item, text) in items as NestedNavItem" :key="text">
            <NavigationItem
              v-if="'route' in item"
              :text="text as string"
              :location="item.route as RouteRecordRaw"
              :is-active="activeEntry?.[0] === section && activeEntry?.[1] === text"
              @click="closeDrawer"
            />
          </template>
        </AccordionContent>
      </Accordion>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.navigation {
  padding: 4px 16px;
  border: none;
  user-select: none;
  background: gfs.$pl_color-white100;
  min-height: 100%;

  &::before {
    content: "";
    display: block;
    background-color: #f0f0f0;
    height: 2px;
    margin-bottom: 24px;
    margin-top: 8px;
  }

  &__header {
    color: gfs.$pl_color-black100;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
