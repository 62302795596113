<template>
  <table class="markdown-table">
    <slot></slot>
  </table>
</template>

<style lang="scss">
@use "@otto-ec/global-frontend-settings/gfs";

.markdown-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  width: 100%;

  th {
    color: gfs.$pl_color-grey400;
    font-size: 0.875rem;
    font-weight: normal;
    padding: 12px;
    text-align: left;
    text-transform: uppercase;
    vertical-align: bottom;

    border-bottom: 1px solid gfs.$pl_color-grey100;
  }

  td {
    padding: 12px;
    vertical-align: top;
    line-height: 1.5;
  }

  td:last-child {
    word-break: break-word;
  }

  tfoot {
    font-size: 0.875rem;
  }

  caption {
    color: #999;
    font-size: 0.875rem;
    text-align: left;
  }

  > tr:nth-of-type(odd),
  tbody tr:nth-of-type(odd) {
    background: gfs.$pl_color-grey25;
  }

  ul {
    list-style-type: disc;
    margin-left: 24px;

    ul {
      list-style-type: circle;
    }
  }

  li {
    margin-bottom: 8px;
  }

  a.pl_link100--primary {
    display: inline; // fix position of link icons in small table cells
  }
}
</style>
