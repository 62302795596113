<template>
  <TextExpander v-model="expanded" class="pl_mb50">
    <slot></slot>
  </TextExpander>
</template>

<script lang="ts" setup>
import { TextExpander } from "../../pattern/TextExpander/index.js";
import { ref } from "vue";

const expanded = ref(false);
</script>
