import {
  RouteLocationNormalizedLoaded,
  RouteMeta,
  RouteParams,
  useRoute as orig,
} from "vue-router";
import { computed, Ref } from "vue";
import { BASE_URL } from "../../utils/index.js";

export type ExtendedRouteParams<Params = RouteParams, Meta = RouteMeta> = Omit<
  RouteLocationNormalizedLoaded,
  "matched" | "params" | "meta"
> & {
  params: Ref<RouteParams & Params>;
  meta: Ref<RouteMeta & Meta>;
};

/**
 * Wraps {@link orig original useRoute} and exposes everything as ComputedRef
 * @template Params used to type route params
 * @template Meta used to type route meta
 * @returns
 */
export function useRoute<Params = RouteParams, Meta = RouteMeta>() {
  const route = orig();

  const name = computed(() => route.name);
  const hash = computed(() => route.hash);
  const path = computed(() => route.path);
  const fullPath = computed(() => route.fullPath);
  const params = computed(() => route.params as RouteParams & Params);
  const query = computed(() => route.query);
  const meta = computed(() => route.meta as RouteMeta & Meta);
  const redirectedFrom = computed(() => route.redirectedFrom);
  const title = computed(() => meta?.value?.title);
  const hostUrl = computed(() => `${window.location.origin}`);
  const base = computed(() => `${hostUrl.value}${BASE_URL}`);
  const href = computed(() => `${base.value}${fullPath.value.substring(1)}`);
  const pathname = computed(() => `${base.value}${path.value.substring(1)}`);

  // Never watch router directly
  // see: https://github.com/vuejs/vue-next/issues/3112
  return {
    name,
    hash,
    path,
    fullPath,
    params,
    query,
    meta,
    redirectedFrom,
    title,
    hostUrl,
    base,
    href,
    pathname,
  };
}
