import "vue";
import "vue-router";

import { createDefaultApp } from "@otto-ec/ottoapi-portal-common";

import reference from "@otto-ec/ottoapi-portal-api-reference";
import home from "@otto-ec/ottoapi-portal-home";
import contact from "@otto-ec/ottoapi-portal-support";

import definedSideNav from "../content/sideNav.js";
import definedTopNav from "../content/topNav.js";

createDefaultApp({
  // Attention: the order of the modules also affects the order in the navigation
  modules: [home, reference, contact],
  definedSideNav,
  definedTopNav,
});
