<template>
  <div class="any-or-one-of-dropdown pl_mb150">
    <Dropdown
      v-model="selectedOption"
      :options="options"
      :label="label"
      :hint-option="label"
      :hint-option-disabled="false"
    ></Dropdown>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { ManifestSchemaItem } from "@otto-ec/ottoapi-cli";
import { Dropdown, DropdownOption, useModelWrapper } from "../../index.js";
import { getChildren } from "./getChildren.js";

const props = defineProps<{ item: ManifestSchemaItem; modelValue: string }>();
const emit = defineEmits(["update:modelValue"]);

const selectedOption = useModelWrapper(props, emit, "modelValue");

const anyOneOfItem = computed(() => getChildren(props.item));

const children = computed(
  () => anyOneOfItem.value["ANY OF"]?.children ?? anyOneOfItem.value["ONE OF"]?.children ?? {}
);

function getStatus(item: ManifestSchemaItem) {
  let status = "";
  if (item.deprecated) {
    status = "(deprecated)";
  } else if (item.preview) {
    status = "(preview)";
  }
  return status;
}

const options = computed<DropdownOption[]>(() => {
  const options = [];
  for (let [key, value] of Object.entries(children.value)) {
    options.push({
      value: key,
      label: `${key} ${getStatus(value)}`,
    });
  }
  return options;
});

const label = computed(() => Object.keys(anyOneOfItem.value)[0]);
</script>

<style lang="scss" scoped>
.any-or-one-of-dropdown {
  display: flex;
}
</style>
