<template>
  <ol class="markdown-ordered-list pl_list--ordered pl_mb50">
    <slot></slot>
  </ol>
</template>

<style lang="scss">
.markdown-ordered-list {
  padding-inline-start: 0;
}
</style>
