<script lang="ts" setup>
import { vBodyScrollLock } from "../../directives/index.js";
import { RouteLocationRaw, useRouter } from "vue-router";
import { SearchResult } from "../../types/index.js";
import Highlight from "./Highlight.vue";

const props = defineProps<{
  selectedIndex: number;
  searchResult: SearchResult;
  itemKey: number;
}>();

const emit = defineEmits(["closeSearch"]);

const router = useRouter();
const { resolve } = useRouter();

const getRoute = (route: RouteLocationRaw) => {
  return resolve(route).fullPath;
};

const getIndices = (matches: SearchResult["matches"] | undefined, key: string) => {
  return matches?.find((m) => m.key === key)?.indices ?? [];
};

const exitSearch = (item: { item: { link: RouteLocationRaw } }) => {
  emit("closeSearch");
  router.push(item.item.link);
};
</script>

<template>
  <div
    v-body-scroll-lock="true"
    class="search-result-item"
    :class="{ 'search-result-item-active': props.selectedIndex === props.itemKey }"
    @click="exitSearch(props.searchResult)"
  >
    <p class="route-text pl_copy50">{{ getRoute(props.searchResult.item.link) }}</p>
    <h3 class="search-headline pl_headline100">
      <Highlight
        :indices="getIndices(props.searchResult.matches, 'title')"
        :value="props.searchResult.item.title"
        is-headline
      />
    </h3>
    <p class="search-description pl_copy75">
      <Highlight
        :indices="getIndices(props.searchResult.matches, 'text')"
        :value="props.searchResult.item.text"
      />
    </p>
  </div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.search-result-item {
  width: 100%;
  padding: 4px 16px;
  transition: background-color 0.3s ease;

  .route-text {
    color: gfs.$pl_color-grey400;
    margin: 0;
  }

  @media (hover: hover) {
    &:hover {
      background-color: gfs.$pl_color-grey100;
      cursor: pointer;
    }
  }

  &-active {
    background-color: gfs.$pl_color-grey100;
  }
}
</style>
