import type { FuseResultMatch } from "fuse.js";

/**
 * flattens the index ranges to positions
 *
 * for example [0,2],[6,7] -> [0,1,2,6,7]
 * @param indices array from search result
 */
export const extractPositions = (indices: FuseResultMatch["indices"]): Set<number> => {
  const result = new Set<number>();

  indices.forEach(([from, to]) => {
    for (let i = from; i <= to; i++) {
      result.add(i);
    }
  });

  return result;
};
