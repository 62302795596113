<template>
  <span class="pl_copy75 markdown-id">
    <slot></slot>
  </span>
</template>

<style lang="scss">
@use "@otto-ec/global-frontend-settings/gfs";

.pl_copy75.markdown-id {
  border-radius: gfs.$pl_border-radius25;
  background: gfs.$pl_color-blue100;
  padding: 0 gfs.$pl_padding50;

  margin-bottom: gfs.$pl_padding25;
  margin-top: gfs.$pl_padding25;

  color: gfs.$pl_color-white100;

  float: right;
}
</style>
