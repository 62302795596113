import type { PortalModule } from "@otto-ec/ottoapi-portal-common";

import routes from "./router/index.js";

export * from "./router/index.js";
export * from "./navigation/index.js";

// Export default as Portal Module structure
const contactModule: PortalModule = {
  routes,
};

export default contactModule;
