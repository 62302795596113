import type { PortalModule } from "@otto-ec/ottoapi-portal-common";

import routes from "./router/index.js";

export * from "./router/index.js";
export * from "./navigation/index.js";
export * from "./slots/index.js";
export * from "./views/index.js";
export * from "./services/index.js";

const referenceModule: PortalModule = { routes };
export default referenceModule;
