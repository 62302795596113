import { computed } from "vue";
import { useTopNav } from "./useTopNav.js";
import { useRoute } from "../router/index.js";
import { definedSideNav } from "../../navigation/index.js";
import type { NestedNavItem } from "../../navigation/index.js";

/**
 * Provides sidenav data and set sidenav as props
 */
export function useSideNav() {
  const { activeEntry: activeTopNavEntry } = useTopNav();

  const sideNav = computed<NestedNavItem>(() =>
    activeTopNavEntry.value ? definedSideNav[activeTopNavEntry.value] : {}
  );

  const { meta } = useRoute<
    unknown,
    {
      sideNav?: string[];
    }
  >();

  const activeEntry = computed(() => meta.value?.sideNav ?? []);

  return {
    sideNav,
    activeEntry,
  };
}
