<template>
  <!--  special case: the schema starts with "any of" or "one of"-->
  <template v-if="isChildAnyOrOneOf">
    <SchemaAnyOrOneOfDropdown
      v-model="selectedAnyOrOneOf"
      class="pl_mt150"
      :item="schema"
    ></SchemaAnyOrOneOfDropdown>

    <SchemaDescription :item="selectedAnyOrOneOfChildren"></SchemaDescription>
  </template>

  <TableView class="pl_mb150">
    <TableHeadline> PROPERTIES</TableHeadline>
    <SchemaEntry
      v-for="(child, title) in children"
      :key="title"
      :item="child"
      :title="title as string"
      :deprecated="child.deprecated"
      :preview="child.preview"
    ></SchemaEntry>
  </TableView>
</template>

<script lang="ts" setup>
import type { ManifestSchemaItem } from "@otto-ec/ottoapi-cli";
import SchemaAnyOrOneOfDropdown from "./SchemaAnyOrOneOfDropdown.vue";
import SchemaEntry from "./SchemaEntry.vue";
import { computed, ref } from "vue";
import { getChildren } from "./getChildren.js";
import { TableHeadline, TableView } from "../pattern/Table/index.js";
import { sortSchemaItem } from "./sortItems.js";
import SchemaDescription from "./SchemaDescription.vue";

const selectedAnyOrOneOf = ref("");

const props = defineProps<{ schema: ManifestSchemaItem }>();

const sortedSchema = computed(() => sortSchemaItem(props.schema));

const schemaChildren = computed(() => getChildren(sortedSchema.value));

const isChildAnyOrOneOf = computed(
  () => "ANY OF" in schemaChildren.value || "ONE OF" in schemaChildren.value
);

const selectedAnyOrOneOfChildren = computed(() => {
  const schemaChildren = getChildren(sortedSchema.value);
  const anyOrOneOf = schemaChildren["ANY OF"] ?? schemaChildren["ONE OF"] ?? {};
  const anyOrOneOfChildren = anyOrOneOf.children ?? {};
  return anyOrOneOfChildren[selectedAnyOrOneOf.value] ?? {};
});

const children = computed(() => {
  const schemaChildren = getChildren(sortedSchema.value);
  if (!isChildAnyOrOneOf.value) {
    return schemaChildren;
  }

  return selectedAnyOrOneOfChildren.value?.children ?? {};
});

function initAnyOrOneOfSelection() {
  selectedAnyOrOneOf.value = "";
  if (isChildAnyOrOneOf.value) {
    const anyOrOneOf = schemaChildren.value["ANY OF"] ?? schemaChildren.value["ONE OF"] ?? {};
    const anyOrOneOfChildren = anyOrOneOf.children ?? {};
    selectedAnyOrOneOf.value = Object.keys(anyOrOneOfChildren)[0];
  }
}

initAnyOrOneOfSelection();
</script>
