<script lang="ts" setup>
const props = defineProps<{
  active: boolean;
  tooltipText: string;
}>();
</script>

<template>
  <Transition name="fade">
    <div
      v-if="active"
      class="tooltip"
      :data-tooltip-text="props.tooltipText"
      data-flow="right"
    ></div>
  </Transition>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translate(-10px);
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.tooltip {
  display: inline-block;
  position: relative;
  top: -5px;
}

.tooltip:before,
.tooltip:after {
  line-height: 1;
  font-size: 12px;
  pointer-events: none;
  position: absolute;
  box-sizing: border-box;
  display: block;
  opacity: 1;
}
.tooltip:before {
  content: "";
  border: 5px solid transparent;
  z-index: 100;
}
.tooltip:after {
  content: attr(data-tooltip-text);
  text-align: center;
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  border-radius: 3px;
  background-color: gfs.$pl_color-black100;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  color: #ffffff;
  z-index: 99;
}

.tooltip[data-flow="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: gfs.$pl_color-black100;
  right: -5px;
  transform: translate(8px, -50%);
}
.tooltip[data-flow="right"]::after {
  top: 50%;
  left: 5px;
  transform: translate(8px, -50%);
}
</style>
