/**
 * removes whitespace from left and right (like trim)
 *
 * @param range
 * @param whitespaces
 * @return trimmed range
 */
export const removeOuterWhitespaces = (range: [number, number], whitespaces: Set<number>) => {
  let [from, to] = range;

  while (whitespaces.has(from)) {
    from++;
  }

  while (whitespaces.has(to)) {
    to--;
  }

  return [from, to];
};
