import { InjectionKey, Ref } from "vue";

export const accordionKey: InjectionKey<{
  isExpanded: Ref<boolean>;
  accordionID: string;
  contentID: string;
}> = Symbol("accordion");

export const accordionGroupKey: InjectionKey<{
  registerAccordion?: (expandedState: Ref<boolean>) => void;
  closeAllAccordions?: () => void;
  isInGroup?: boolean;
}> = Symbol("accordionGroup");
