<script lang="ts" setup>
import SearchResultItem from "./SearchResultItem.vue";
import Chip from "../Chip/Chip.vue";
import { definedTopNav } from "../../navigation/index.js";
import { useSearch } from "../../use/search/index.js";
import { vScrollTo, vBodyScrollLock } from "../../directives/index.js";

const props = defineProps<{
  selectedIndex: number;
}>();

const { results } = useSearch();

const emit = defineEmits(["closeSearch"]);

const exitSearch = () => {
  emit("closeSearch");
};
</script>

<template>
  <div class="chips-container">
    <div v-for="item in Object.keys(definedTopNav)" :key="item" class="chip-item">
      <Chip :item-string="item" />
    </div>
  </div>
  <div v-if="results.length <= 0" v-body-scroll-lock="true" class="not-found-item">
    <p data-not-found-text class="not-found-text">
      Your search did not return any matches. <br />
      Try another keyword or adjust your filter.
    </p>
  </div>
  <div v-else class="results-container">
    <SearchResultItem
      v-for="(item, k) in results"
      :key="k"
      v-scroll-to="props.selectedIndex === k"
      :selected-index="props.selectedIndex"
      :search-result="item"
      :item-key="k"
      @close-search="exitSearch()"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.chips-container {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 3rem;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  background-color: white;
  height: 56px;
  z-index: 1000;
}

.not-found-item {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 6.5rem;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  background-color: white;
  height: 160px;
  z-index: 1000;
  border-radius: 0 0 1.5rem 1.5rem;

  .not-found-text {
    text-align: center;
    font-size: 13px;
    line-height: 2rem;
    margin: 0;
    padding-top: 2.5rem;
    padding-bottom: 2rem;
  }
}

.results-container {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 6.5rem;
  width: 100%;
  max-height: 382px;
  z-index: 999;
  background-color: gfs.$pl_color-white100;
  border-radius: 0 0 1.5rem 1.5rem;
  overflow-y: auto;
  scrollbar-width: none; //for firefox

  //webkit (chrome, opera, edge)
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &:first-child {
    background-color: red;
  }
}
</style>
