import { RouteRecordRaw } from "vue-router";

export enum RouteNames {
  home = "home",
}

export default [
  {
    path: "/",
    name: RouteNames.home,
    component: () => import("../views/Home.vue"),
    meta: { title: "Portal", layout: "FullLayout" },
  },
] as RouteRecordRaw[];
