<script lang="ts" setup>
import { ref } from "vue";
import { useSearch } from "../../use/search/index.js";
import Icon from "../pattern/Icon/Icon.vue";

const props = defineProps<{
  itemString: string;
}>();

const { modules } = useSearch();
const isToggled = ref(modules.has(props.itemString));

const toggleFilter = (itemString: string) => {
  if (modules.has(itemString)) {
    isToggled.value = false;
    modules.delete(itemString);
  } else {
    modules.add(itemString);
    isToggled.value = true;
  }
};
</script>

<template>
  <span
    role="button"
    :class="{ chip__toggled: isToggled }"
    class="pl_chip100 pl_chip100--grey api-portal-chip"
    @click="toggleFilter(itemString)"
  >
    <Icon
      v-if="isToggled"
      class="pl_icon50 api-portal-chip__icon"
      :class="{ toggled: isToggled }"
      icon="pl_icon_check50"
    />
    <span class="api-portal-chip__text">{{ itemString }}</span>
  </span>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.api-portal-chip {
  width: 100%;
  transition: width 0.3s ease, background-color 0.3s ease;

  .api-portal-chip__icon {
    width: 0;
    margin-right: 4px;

    &.toggled {
      width: 12px;
    }
  }
}

.chip__toggled {
  background-color: transparentize(gfs.$pl_color-blue100, 0.8);

  .api-portal-chip__text {
    font-weight: bold;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #7dc7ff;
    }
  }
}
</style>
