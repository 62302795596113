import { DefinedTopNavConfig, defineTopNav } from "@otto-ec/ottoapi-portal-common/navigation";
import { support } from "@otto-ec/ottoapi-portal-support/navigation";
import { topNav as guidelinesTopNav } from "@otto-ec/ottoapi-portal-guidelines";

export default defineTopNav({
  "REST API": {
    public: true,
    file: () => import("./rest-api/index.md"),
    path: "/rest/getting-started/introduction",
  },
  ...(guidelinesTopNav as DefinedTopNavConfig),
  "API Gateway": {
    file: () => import("./gateway/about-the-gateway/introduction.md"),
    path: "/gateway/about-the-gateway/introduction",
  },
  "Support": {
    public: true,
    ...support,
  },
  "Wiki": {
    file: () => import("./wiki/index.md"),
    path: "/wiki/introduction",
  },
});
