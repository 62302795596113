import type { Ref } from "vue";
import { useMediaQuery as orig } from "@vueuse/core";

/**
 * exposes media queries as refs
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useMediaQuery(width = "0px") {
  // const sm = 448;
  const md = 768;
  const lg = 992;
  const xl = 1280;

  return {
    smOnly: orig(`only screen and (max-width: ${md - 1}px)`) as Ref<boolean>,
    mdOnly: orig(
      `only screen and (min-width: ${md}px) and (max-width: ${lg - 1}px)`
    ) as Ref<boolean>,
    lgOnly: orig(
      `only screen and (min-width: ${lg}px) and (max-width: ${xl - 1}px)`
    ) as Ref<boolean>,
    xlOnly: orig(`only screen and (min-width: ${xl}px)`) as Ref<boolean>,
    mdAndDown: orig(`only screen and (max-width: ${lg - 1}px)`) as Ref<boolean>,
    lgAndDown: orig(`only screen and (max-width: ${xl - 1}px)`) as Ref<boolean>,
    widthAndDown: orig(`only screen and (max-width: ${width})`) as Ref<boolean>,
    mdAndUp: orig(`only screen and (min-width: ${md}px)`) as Ref<boolean>,
    lgAndUp: orig(`only screen and (min-width: ${lg}px)`) as Ref<boolean>,
    widthAndUp: orig(`only screen and (min-width: ${width})`) as Ref<boolean>,
  };
}
