<script setup lang="ts">
import { inject, ref, watch } from "vue";
import { accordionKey } from "./injectionKeys.js";

const { isExpanded, accordionID, contentID } = inject(accordionKey, {});

const openedOnce = ref(isExpanded.value);
const stopWatchingOpen = watch(isExpanded, (val) => {
  if (val) {
    openedOnce.value = true;
    stopWatchingOpen();
  }
});
</script>
<template>
  <div
    :id="contentID"
    class="accordion-content"
    :class="{ 'accordion-content--expanded': isExpanded }"
    :aria-labelledby="accordionID"
    role="region"
  >
    <div v-if="openedOnce" class="accordion-content__inner">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.accordion-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.25s cubic-bezier(0.33, 1, 0.68, 1);

  &--expanded {
    grid-template-rows: 1fr;
    padding-bottom: 12px;
  }

  &__inner {
    overflow: hidden;
  }
}
</style>
