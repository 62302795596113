<template>
  <div v-if="title !== 'x-extensible-enum'" class="attribute">
    <span>{{ title }}:&nbsp;</span>
    <span>{{ values }}</span>
  </div>
  <SchemaExtensibleEnumAttribute v-else :items="item"> </SchemaExtensibleEnumAttribute>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { ManifestAttributes } from "@otto-ec/ottoapi-cli";
import SchemaExtensibleEnumAttribute from "./SchemaExtensibleEnumAttribute.vue";

const props = defineProps<{ title: string; item: ManifestAttributes[0] }>();

const values = computed(() => props.item.map((i) => "" + i).join(", "));
</script>

<style lang="scss" scoped>
.attribute {
  display: flex;
  font-family: monospace;
}
</style>
