<script setup lang="ts">
import { inject } from "vue";
import Icon from "../pattern/Icon/Icon.vue";
import { accordionGroupKey, accordionKey } from "./injectionKeys.js";

const { isExpanded, accordionID, contentID } = inject(accordionKey, {});
const { isInGroup, closeAllAccordions } = inject(accordionGroupKey, {});

function toggle() {
  if (!isExpanded.value && isInGroup) {
    closeAllAccordions?.();
  }
  isExpanded.value = !isExpanded.value;
}
</script>

<template>
  <h4 class="accordion-header">
    <button
      :id="accordionID"
      :aria-controls="contentID"
      :aria-expanded="isExpanded"
      class="accordion-header__button"
      @click="toggle"
    >
      <slot></slot>
      <Icon icon="pl_icon_arrow-down" class="accordion-header__icon" />
    </button>
  </h4>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.accordion-header {
  line-height: 24px;
  font-size: 0.875rem;

  &__button {
    all: unset;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: normal;
    padding: 12px 16px 12px 0;

    &:focus-visible {
      outline: gfs.$pl_color-blue100 2px solid;
    }
  }

  &__icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: auto;
    transition: transform 0.2s ease-out;
  }
}

.accordion-header__button[aria-expanded="true"] .accordion-header__icon {
  transform: rotate(-180deg);
}
</style>
