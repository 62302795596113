<script lang="ts">
import { computed, defineComponent } from "vue";

import PortalHeader from "./components/header/PortalHeader.vue";
import DefaultLayout from "./layout/DefaultLayout.vue";
import FullLayout from "./layout/FullLayout.vue";

import { useRoute } from "./use/index.js";
import InfoBanner from "./components/Banner/InfoBanner.vue";

export default defineComponent({
  components: { DefaultLayout, PortalHeader, FullLayout, InfoBanner },
  setup() {
    const { meta } = useRoute<unknown, { layout?: string }>();

    const layout = computed(() => meta.value.layout || "DefaultLayout");

    return { layout };
  },
});
</script>

<template>
  <InfoBanner />
  <div class="page-header">
    <PortalHeader />
  </div>
  <div class="page-main">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<style lang="scss">
@use "@otto-ec/global-frontend-settings/gfs";
@use "./scss/variables";

.page-header {
  height: variables.$header-height;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: gfs.$pl_color-white100;
  border-bottom: 4px solid gfs.$pl_color-grey25;
}

.page-main {
  position: absolute;
  top: variables.$header-height;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
</style>
