import { computed } from "vue";

import { useRoute } from "../router/index.js";
import { definedTopNav } from "../../navigation/index.js";

export function useTopNav() {
  const { meta } = useRoute<
    unknown,
    {
      topNav?: string;
    }
  >();

  const activeEntry = computed(() => meta.value?.topNav ?? "");
  const activeParentEntry = computed(
    () => Object.entries(definedTopNav).find(([, v]) => activeEntry.value in v)?.[0]
  );

  return {
    /** Current TopNav based on registered routes */
    topNav: definedTopNav,
    activeEntry,
    activeParentEntry,
  };
}
