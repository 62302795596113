<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  type?: "MUST" | "MAY" | "SHOULD" | "MUST NOT" | "MAY NOT" | "SHOULD NOT";
}>();

const cls = computed(() => {
  if (!props.type) {
    return "";
  }
  return `guidelines-${props.type.replace(" NOT", "").toLowerCase()}`;
});
</script>

<template>
  <span v-if="type" :class="cls">{{ type }}</span>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.guidelines-may {
  color: gfs.$pl_color-sustainable;
}

.guidelines-must {
  color: gfs.$pl_color-red100;
}

.guidelines-should {
  color: #f0c000;
}
</style>
