/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { RouteRecordRaw } from "vue-router";

export * from "./consts.js";

export const devRoutes: RouteRecordRaw[] = [
  {
    path: "/:dev/reference/:context",
    props: true,
    component: () => import("../dev/DevReference.vue"),
  },
  {
    path: "/dev/operations/:context/:operation",
    props: true,
    component: () => import("../views/Reference.vue"),
  },
];

export default import.meta.env.DEV ? devRoutes : [];
