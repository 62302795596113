import type { FuseResultMatch } from "fuse.js";
import { findAllWhitespaces } from "./findAllWhitespaces.js";
import { extractPositions } from "./extractPositions.js";
import { getLongestRangeFromPositions } from "./getLongestRangeFromPositions.js";
import { removeOuterWhitespaces } from "./removeOuterWhitespaces.js";
import { findFullMatch } from "./findFullMatch.js";

/**
 * calculates the longest range
 *
 * @param indices array from search result
 * @param text
 * @param searchTerm
 * @return longest range
 */
export const findLongestIndex = (
  indices: FuseResultMatch["indices"],
  text: string,
  searchTerm: string,
) => {
  findFullMatch(text, searchTerm);

  for (const word of searchTerm.split(/\s+/gm)) {
    if (word.length === 0) {
      continue;
    }

    findFullMatch(text, searchTerm, word);
  }

  // [0,2],[6,7] -> [0,1,2,6,7]
  const positions = extractPositions(indices);

  // "foo bar baz" -> [3,7]
  const whitespaces = findAllWhitespaces(text);

  // merge [0,1,2,6,7] + [3,7] -> [0,1,2,3,6,7]
  const mergedPositions = new Set([...whitespaces, ...positions]);

  // find longest range [0,1,2,3,6,7] -> [0,3]
  const longestRange = getLongestRangeFromPositions(mergedPositions, whitespaces);

  // remove whitespaces
  return removeOuterWhitespaces(longestRange, whitespaces);
};
