<script lang="ts" setup>
import { computed, ref } from "vue";
import { ManifestSchemaItem, ManifestSchemaItems } from "@otto-ec/ottoapi-cli";
import SchemaEntry from "./SchemaEntry.vue";
import { Dropdown, DropdownOption } from "../pattern/Dropdown/index.js";
import SchemaHeader from "./SchemaHeader.vue";
import SchemaDescription from "./SchemaDescription.vue";
import { getChildren } from "./getChildren.js";

const props = defineProps<{
  item: ManifestSchemaItem;
  title: string;
  deprecated: boolean;
  preview: boolean;
}>();

const anyOneOfItem = computed(() => getChildren(props.item));

const children = computed(
  () => anyOneOfItem.value["ANY OF"]?.children ?? anyOneOfItem.value["ONE OF"]?.children ?? {}
);

// merge oneOf subschemas
const childrenMerged = computed(() => {
  let oneOfChildren: ManifestSchemaItems = {};
  for (let [key, value] of Object.entries(children.value)) {
    if (value.children && value.children["ONE OF"]) {
      oneOfChildren = { ...value.children["ONE OF"].children };
    } else {
      oneOfChildren[key] = value;
    }
  }
  return oneOfChildren;
});

function getStatus(item: ManifestSchemaItem) {
  let status = "";
  if (item.deprecated) {
    status = "(deprecated)";
  } else if (item.preview) {
    status = "(preview)";
  }
  return status;
}

const options = computed<DropdownOption[]>(() => {
  const options = [];
  for (let [key, value] of Object.entries(childrenMerged.value)) {
    options.push({
      value: key,
      label: `${key} ${getStatus(value)}`,
    });
  }
  return options;
});

const selectedOption = ref("");

const selectedChild = computed(() => childrenMerged.value[selectedOption.value]);

const label = computed(() => Object.keys(anyOneOfItem.value)[0]);
</script>

<template>
  <div class="schema-any-one-of">
    <div class="schema-any-one-of-header">
      <SchemaHeader
        class="pl_py25"
        :item="item"
        :title="title"
        :deprecated="deprecated"
      ></SchemaHeader>
      <div class="schema-dropdown" :class="{ 'schema-dropdown--deprecated': deprecated }">
        <Dropdown
          v-model="selectedOption"
          :options="options"
          :label="label"
          :hint-option="label"
          :hint-option-disabled="false"
          :deprecated="deprecated"
        />
      </div>
    </div>
    <template v-if="selectedOption">
      <SchemaDescription :item="selectedChild" :deprecated="deprecated"></SchemaDescription>

      <SchemaEntry
        v-for="(child, childTitle) in selectedChild.children"
        :key="childTitle"
        :title="childTitle as string"
        :item="child"
        :deprecated="deprecated || selectedChild?.deprecated"
        :preview="preview || selectedChild?.preview"
        class="pl_ml150"
      ></SchemaEntry>
    </template>

    <template v-else>
      <SchemaDescription :item="item" :deprecated="deprecated"></SchemaDescription>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.schema-dropdown {
  width: 100%;
  max-width: 300px;
  position: relative;

  &--deprecated {
    opacity: 0.45;
  }

  .pl_dropdown {
    position: absolute;
    left: gfs.$pl_padding25;
    top: -(gfs.$pl_padding75);
    width: 100%;
    min-width: 80px;
    transform: scale(0.875); // :(
  }
}

.schema-any-one-of {
  border-top: 1px solid gfs.$pl_color-grey100;

  .schema-any-one-of-header {
    display: flex;
    padding: gfs.$pl_padding150 0;
    column-gap: 6px;
  }
}
</style>
