import { processSideNav } from "./processSideNav.js";
import type { DefinedSideNavConfig, SideNavConfig } from "./types.js";

export function defineSideNav(definedSideNav: DefinedSideNavConfig): SideNavConfig {
  return Object.fromEntries(
    Object.entries(definedSideNav)
      .map(([k, v]) => [k, processSideNav(v, k)])
      .filter(([, v]) => Object.keys(v).length > 0)
  );
}
