import { Directive } from "vue";

/**
 * usage:
 * v-scroll-to="...condition..."
 */
export const vScrollTo: Directive = {
  updated(el, binding) {
    binding.value && el.scrollIntoView({ behavior: "auto", block: "nearest" });
  },
};
