<script lang="ts" setup>
import { ref } from "vue";
import { useModelWrapper } from "../../../use/index.js";
import { visibleOnce as vVisibleOnce } from "../../../directives/visibility/visibleOnce.js";

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

const isExpanded = useModelWrapper(props, emit, "modelValue");

const isVisible = ref(props.modelValue);
</script>

<template>
  <div
    v-visible-once="() => (isVisible = true)"
    class="pl_text-expander"
    :class="{ 'pl_text-expander--expanded': isExpanded }"
  >
    <slot v-if="isVisible"></slot>

    <div
      v-if="isVisible"
      class="pl_text-expander__toggle pl_px100"
      @click="isExpanded = !isExpanded"
    >
      <a class="pl_link100--primary pl_text-expander__link js_pl_text-expander__link"></a>
    </div>
  </div>
</template>

<style lang="scss">
.pl_text-expander__toggle {
  .pl_text-expander__link {
    &:after {
      content: "Show more";
    }
  }
}

.pl_text-expander.pl_text-expander--expanded {
  .pl_text-expander__toggle {
    .pl_text-expander__link {
      &:after {
        content: "Hide";
      }
    }
  }
}
</style>
