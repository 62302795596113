<script lang="ts" setup>
import { useTopNav } from "../../use/index.js";
import { Icon } from "../pattern/Icon/index.js";
import { ref } from "vue";
import { useTimeoutFn } from "@vueuse/shared";
import Search from "../Search/Search.vue";
import PortalHeaderLogo from "./PortalHeaderLogo.vue";
import { RouteRecordRaw } from "vue-router";

const { topNav, activeEntry, activeParentEntry } = useTopNav();

const activeGroup = ref<string>();
const isSearchExpanded = ref(false);

const { start, stop } = useTimeoutFn(
  () => {
    activeGroup.value = undefined;
  },
  300,
  {
    immediate: false,
  }
);

const groupLeave = () => {
  start();
};

const groupEnter = (id: string) => {
  stop();
  activeGroup.value = id;
};

const openSearch = () => {
  isSearchExpanded.value = true;
};

const closeSearch = () => {
  isSearchExpanded.value = false;
};
</script>

<template>
  <div class="header">
    <div class="header__logo">
      <PortalHeaderLogo />
    </div>

    <nav class="header__nav" role="menubar">
      <template v-for="(item, title) in topNav" :key="title">
        <router-link
          v-if="'route' in item"
          :to="'route' in item ? item.route as RouteRecordRaw: ''"
          :class="{ 'is-active': activeEntry === title }"
          class="menuitem pl_px100 pl_link100--secondary"
        >
          <span>{{ title }}</span>
        </router-link>
        <div
          v-else
          class="group pl_px100 pl_copy100"
          :class="{ 'is-active': activeParentEntry === title }"
          @mouseenter="groupEnter(title as string)"
          @mouseleave="groupLeave"
        >
          <span>{{ title }}</span>
          <Icon
            :icon="activeGroup === title as string ? 'pl_icon_arrow-up50' : 'pl_icon_arrow-down50'"
            size="50"
          ></Icon>

          <div v-show="activeGroup === title as string" class="group-dropdown">
            <router-link
              v-for="(child, childTitle) in item"
              :key="childTitle"
              :to="'route' in child ? child.route as RouteRecordRaw : ''"
              :class="{ 'is-active': activeEntry === childTitle }"
              class="group-item pl_p100 pl_link100--secondary"
              role="menuitem"
            >
              <span>{{ childTitle }}</span>
            </router-link>
          </div>
        </div>
      </template>
    </nav>

    <div class="header__search" :class="isSearchExpanded ? 'expanded-with-backdrop' : ''">
      <Search
        :is-search-expanded="isSearchExpanded"
        :has-focus="isSearchExpanded"
        :has-input="isSearchExpanded"
        :is-mobile="false"
        placeholder="Search"
        @close-search="closeSearch"
        @open-search="openSearch"
      />
      <template v-if="isSearchExpanded">
        <div
          class="search-backdrop"
          :class="{ 'search-backdrop--hidden': !isSearchExpanded }"
          @click="closeSearch()"
        ></div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";
@use "../../scss/variables";

.header {
  position: relative;
  max-width: variables.$max-width;
  height: variables.$header-height;
  margin: auto;
  padding-bottom: 4px;
  display: flex;

  &__nav {
    height: 100%;
    display: flex;
    flex: 0 1 1024px;
  }

  &__search {
    position: absolute;
    right: 0;
    top: 8px;
    display: flex;
    height: 100%;
    width: 268px;
    transition: width 0.3s ease;
    padding: 0 16px;

    &.expanded-with-backdrop {
      width: 100%;

      .search-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 40%);
      }
    }
  }
}

.group,
.menuitem {
  cursor: pointer;
  color: gfs.$pl_color-grey400;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  border-bottom: gfs.$pl_color-white100 solid 2px;
  margin-top: 1px;

  &:hover {
    background: lighten(gfs.$pl_color-red100, 50%);
  }

  &.is-active {
    border-bottom: gfs.$pl_color-red100 solid 2px;
    color: gfs.$pl_color-black100;
  }
}

.group {
  gap: gfs.$pl_padding75;
  position: relative;

  > svg {
    fill: gfs.$pl_color-grey400;
  }

  &.is-active > svg {
    fill: gfs.$pl_color-black100;
  }
}

.group-item {
  color: gfs.$pl_color-grey400;

  &.is-active {
    color: gfs.$pl_color-black100;
  }
}

.group-dropdown {
  position: absolute;
  z-index: 2;
  top: variables.$header-height;
  left: 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: gfs.$pl_border-radius25;
  background-color: gfs.$pl_color-white100;
  border: 1px solid gfs.$pl_color-grey200;
}
</style>
