<script lang="ts" setup>
import { useModelWrapper } from "../../../use/index.js";
import { DropdownOption } from "./DropdownOption.js";

const props = withDefaults(
  defineProps<{
    modelValue: string;
    options: DropdownOption[];
    label: string;
    hintOption?: string;
    hintOptionDisabled?: boolean;
    deprecated?: boolean;
  }>(),
  {
    hintOptionDisabled: true,
    hintOption: "Please select one",
  }
);

const emit = defineEmits(["update:modelValue"]);

const selected = useModelWrapper(props, emit, "modelValue");
</script>

<template>
  <div class="pl_dropdown">
    <select v-model="selected" class="pl_dropdown__element" :data-value="selected">
      <option :disabled="hintOptionDisabled" value="">{{ hintOption }}</option>
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </select>
    <label class="pl_label">{{ label }}</label>
  </div>
</template>

<style lang="scss">
@use "@otto-ec/global-frontend-settings/gfs";

.pl_dropdown {
  margin-bottom: unset;
  margin-top: unset;

  .pl_dropdown__element {
    width: calc(100% + gfs.$pl_padding25);
  }
}
</style>
