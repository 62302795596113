<template>
  <ul class="markdown-unordered-list pl_list--unordered pl_mb50">
    <slot></slot>
  </ul>
</template>

<style lang="scss">
.markdown-unordered-list {
  padding-inline-start: 0;
}
</style>
