<script lang="ts" setup>
import { Banner } from "./index.js";
import { useRoute } from "../../utils/index.js";

const blacklistedLinks = {
  DEVELOP: "https://api.develop.otto.de/portal/",
};

const isBannerDisabled = localStorage.getItem("Prevent Info-Banner");

const { base } = useRoute();
const isBlackListedLink = Object.values(blacklistedLinks).includes(base.value);

const showBanner = isBlackListedLink && isBannerDisabled !== "true";

if (showBanner) {
  console.info(
    "INFO: to disable the info banner write",
    'localStorage.setItem("Prevent Info-Banner", "true")'
  );
}
</script>

<template>
  <Banner v-if="showBanner">
    <span>
      This environment is not stable and used for testing purposes only. Use our stable environments
      <a href="https://api.otto.de/portal" target="_blank">api.otto.de/portal</a> (live platform,
      public content only) or
      <a href="https://internal.api.otto.de/portal" target="_blank">internal.api.otto.de/portal</a>
      (internal platform, public and internal content) instead.
    </span>
  </Banner>
</template>
