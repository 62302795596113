<script lang="ts" setup>
import { useSearch } from "../../use/search/index.js";
import Icon from "../pattern/Icon/Icon.vue";

const props = defineProps<{
  inputfield: any;
  isMobile: boolean;
  isSearchExpanded: boolean;
}>();

const emit = defineEmits(["openSearch"]);

const { input } = useSearch();

const clearInput = () => {
  if (props.inputfield.value !== undefined) {
    input.value = "";
    emit("openSearch");
  }
};
</script>

<template>
  <!-- Clear icon when having input on desktop with search not expanded -->
  <div v-if="!props.isSearchExpanded && !isMobile" class="safe-area action-icon clear-icon">
    <Icon data-clear-icon icon="pl_icon_clear" size="100" @click="clearInput()" />
  </div>

  <!-- Clear icon when having input on desktop with search expanded -->
  <div v-if="props.isSearchExpanded && !isMobile" class="safe-area action-icon clear-icon">
    <Icon data-clear-icon icon="pl_icon_clear" size="100" @click="clearInput()" />
  </div>

  <!-- Clear icon when having input on mobile -->
  <div
    v-else-if="props.isSearchExpanded && props.isMobile"
    class="safe-area action-icon clear-icon"
  >
    <Icon data-clear-icon icon="pl_icon_clear" size="100" @click="clearInput()" />
  </div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.action-icon {
  user-select: none;
  &.clear-icon svg {
    fill: gfs.$pl_color-grey400;
  }
}
</style>
