<script lang="ts" setup>
import { ref } from "vue";
import PortalHeaderLogo from "./PortalHeaderLogo.vue";
import { Icon } from "../pattern/Icon/index.js";

import { Drawer } from "../Drawer/index.js";
import Search from "../Search/Search.vue";

const isDrawerOpen = ref(false);
const isSearchExpanded = ref(false);

const openDrawer = () => {
  isDrawerOpen.value = true;
};

const closeDrawer = () => {
  isDrawerOpen.value = false;
};

const openSearch = () => {
  isSearchExpanded.value = true;
};

const closeSearch = () => {
  isSearchExpanded.value = false;
};
</script>

<template>
  <div class="header">
    <nav class="header__nav" role="menubar" @click="openDrawer()">
      <Icon icon="pl_icon_nav" size="100" />
    </nav>

    <div class="header__logo">
      <PortalHeaderLogo />
    </div>

    <div
      class="header__search pl_px100"
      :class="{ 'header__search--expanded-with-backdrop': isSearchExpanded }"
    >
      <Search
        class="header__search-input-mobile"
        :class="{ 'header__search--enabled': isSearchExpanded }"
        :is-search-expanded="isSearchExpanded"
        :has-focus="isSearchExpanded"
        :has-input="isSearchExpanded"
        :is-mobile-search-closed="isSearchExpanded"
        is-mobile
        @close-search="closeSearch"
        @open-search="openSearch"
      />
      <div v-if="isSearchExpanded">
        <div class="header__search-backdrop" @click="closeSearch()"></div>
      </div>
    </div>
    <div v-if="isDrawerOpen">
      <Drawer @close-drawer="closeDrawer" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";
@use "../../scss/main" as *;
@use "../../scss/variables";

.header {
  position: relative;
  max-width: variables.$max-width;
  height: variables.$header-height;
  margin: auto;
  padding-bottom: 4px;
  display: flex;

  &__nav {
    @extend .safe-area;
  }

  &__search {
    display: flex;
    position: absolute;
    right: 0;
    top: 8px;
    height: 100%;
    width: 5rem;
    transition: width 0.3s ease;

    &--expanded-with-backdrop {
      width: 100%;
    }

    &-input-mobile,
    &--input-mobile:hover {
      background-color: transparent;
      outline: none;
    }

    &--enabled {
      background-color: gfs.$pl_color-grey25;
    }

    &-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
