<template>
  <SvgUse :href="href" :size="size"></SvgUse>
</template>

<script lang="ts" setup>
import SvgUse from "../../SvgUse/SvgUse.vue";
import { computed } from "vue";
import { BASE_URL } from "../../../utils/index.js";
import { IconPropType } from "./index.js";

const props = withDefaults(
  defineProps<{
    icon: IconPropType;
    size?: "50" | "100" | "";
  }>(),
  {
    size: "",
  }
);

const href = computed(() => `${BASE_URL}common/icons/${props.icon}.svg#${props.icon}`);
</script>
