import type { Directive } from "vue";

/**
 * if element is first time visible the passed callback will be called once
 *
 * @example
 * @code
 * ```vue
 * <template>
 *   <div v-visible-once="() => (visible = true)">
 *     <div v-if="visible">
 *       I am rendered if first time visible
 *     </div>
 *   </div>
 * </template>
 *
 * <script lang="ts">
 *  import { defineComponent, ref } from "vue";
 *  import { visibleOnce } from "@Common/directives";
 *
 *  export default defineComponent({
 *   directives: {
 *     visibleOnce,
 *   },
 *   setup() {
 *     const visible = ref(false);
 *     return { visible };
 *   },
 * });
 *  </script>
 * ```
 */
export const visibleOnce: Directive<Element, () => void> = (el, binding) => {
  const io = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      io.disconnect();
      binding.value();
    }
  });

  io.observe(el);
};
