<template>
  <div class="markdown-footnotes">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.markdown-footnotes {
}
</style>
