<script lang="ts" setup>
import NavigationItem from "./NavigationItem.vue";
import { useSideNav } from "../../use/index.js";
import { NestedNavItem } from "../../navigation/index.js";
import { RouteRecordRaw } from "vue-router";

const { sideNav, activeEntry } = useSideNav();
</script>

<template>
  <div v-if="sideNav" class="navigation pl_p100">
    <template v-for="(items, section) in sideNav" :key="section">
      <div class="navigation__header pl_copy75">
        {{ section }}
      </div>

      <template v-for="(item, text) in items as NestedNavItem" :key="text">
        <NavigationItem
          v-if="'route' in item"
          :text="text as string"
          :location="item.route as RouteRecordRaw"
          :is-active="activeEntry?.[0] === section && activeEntry?.[1] === text"
        />
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.navigation {
  border: none;
  user-select: none;
  background: gfs.$pl_color-white100;
  min-height: 100%;

  &__header {
    border-bottom: 1px solid gfs.$pl_color-grey100;
    color: gfs.$pl_color-grey400;
    padding: gfs.$pl_padding50;
    margin-top: gfs.$pl_padding100;
  }
}
</style>
