import type { ManifestSchemaItem, ManifestSchemaItems } from "@otto-ec/ottoapi-cli";

const ITEMS_TO_SORT_AT_END = ["_embedded", "_links"];

export function sortSchemaItem(schema: ManifestSchemaItem): ManifestSchemaItem {
  if (!schema.children) {
    return schema;
  }

  const children = sortExampleItem(schema.children) as ManifestSchemaItems;

  return { ...schema, children };
}

export function sortExampleItem(example: any): any {
  return Object.fromEntries(
    Object.entries(example).sort(([a], [b]) => {
      const indexA = ITEMS_TO_SORT_AT_END.indexOf(a) ?? ITEMS_TO_SORT_AT_END.length;
      const indexB = ITEMS_TO_SORT_AT_END.indexOf(b) ?? ITEMS_TO_SORT_AT_END.length;
      if (indexA === indexB) {
        return 0;
      }
      return indexA > indexB ? 1 : -1;
    })
  );
}
