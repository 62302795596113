<template>
  <a v-if="isExternal" :href="external.href" target="_blank" :class="linkClass">
    <span
      ><slot>{{ external.text }}</slot></span
    >&nbsp;<Icon icon="pl_icon_leaving-page50" size="50"></Icon>
  </a>

  <router-link v-else :to="internal" :class="linkClass">
    <span
      ><slot>{{ internal.text }}</slot></span
    >
  </router-link>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { LinkLocation, LocationExternal, LocationInternal } from "../../types/sidenav.js";
import { Icon } from "../pattern/Icon/index.js";

const props = defineProps<{ location: LinkLocation; secondary?: boolean }>();

const isExternal = computed(
  () => props.location && typeof props.location !== "string" && "href" in props.location
);

const internal = computed(() => props.location as LocationInternal);
const external = computed(() => props.location as LocationExternal);

const linkClass = computed(() =>
  props.secondary ? "pl_link100--secondary" : "pl_link100--primary"
);
</script>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.pl_link100--primary {
  display: inline-flex;
  align-items: center;

  > svg {
    fill: gfs.$pl_color-blue100;
  }

  &:hover,
  &:active {
    > svg {
      fill: darken(gfs.$pl_color-blue100, 30);
    }
  }
}

.pl_link100--secondary {
  > svg {
    fill: gfs.$pl_color-grey400;
  }

  &:hover,
  &:active {
    > svg {
      fill: darken(gfs.$pl_color-grey400, 30);
    }
  }
}
</style>
