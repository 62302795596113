import { RouteNames, routePaths } from "../router/index.js";
import type { DefinedNestedNavItem } from "@otto-ec/ottoapi-portal-common";

/**
 * Define nested side-nav items for Api-References section
 * @param contextArray
 */
export function reference(contextArray: string[]) {
  const nestedItems: Record<string, DefinedNestedNavItem> = {};

  contextArray.forEach((context) => {
    nestedItems[context] = {
      public: true,
      route: {
        path: `/reference/specs/${context}`,
        name: RouteNames.context + context,
      },
      redirectHash: `#${context}`,
      redirectToParent: true,
    };

    nestedItems[`${context}Operation`] = {
      public: true,
      route: {
        path: `/reference/specs/${context}/operations/:operation/:version?`,
        name: RouteNames.operation + context,
        component: () => import("../views/Reference.vue"),
        props: ({ params }) => ({
          ...params,
          context: context,
        }),
      },
    };
  });

  return nestedItems;
}

/**
 * Define nested side-nav items for Profiles section
 */
export function profiles() {
  return {
    route: {
      path: routePaths[RouteNames.profiles],
      name: RouteNames.profiles,
    },
    profileDetail: {
      public: true,
      route: {
        path: routePaths[RouteNames.profileDetail],
        name: RouteNames.profileDetail,
        component: () => import("../views/profile/Profile.vue"),
        props: true,
      },
    },
  };
}
