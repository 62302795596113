import { computed, SetupContext, WritableComputedRef } from "vue";

/**
 * Creates helper fro bidirectional binding for `v-model`
 *
 * In case the component declares an event like "foo", then it must also
 * declare `update:${K}` event, so the types are compatible
 *
 * the property to bind should be named `modelValue`. in this case it is possible
 * to use default `v-model="somevalue"`
 *
 * otherwise `v-model:thefoo="somevalue"` syntax must be used on usage
 *
 * @param props props object from setup props
 * @param emit emit function from setup context
 * @param name name of the property to bind default propName is `modelValue`
 * @returns ComputedRef for bi-directional binded property
 *
 * @example
 * @code
 *
 * ```typescript
 * export default defineComponent({
 *   props: {
 *     modelValue: {
 *       type: String as PropType<string>,
 *       required: true,
 *     },
 *   },
 *   setup(props, { emit }) {
 *     const thefoo = useModelWrapper(props, emit, "modelValue");
 *
 *     // triggers update
 *     thefoo.value = "newValue"
 *
 *     return { thefoo };
 *   },
 * });
 * ```
 */
export const useModelWrapper = <
  P,
  K extends string & keyof P,
  E extends SetupContext<[`update:${K}`]>["emit"]
>(
  props: P,
  emit: E,
  name: K
): WritableComputedRef<P[K]> =>
  computed({
    get: () => props[name],
    set: (val) => emit(`update:${name}`, val),
  });
