import type { DefinedNestedNavItem, NestedNavItem } from "./types.js";
import type { RouteRecordRaw } from "vue-router";
import { isItemVisible } from "./isItemVisible.js";

const reservedKeys = ["file", "path", "route", "redirectToParent", "redirectHash", "public"];

function createRoute(
  item: DefinedNestedNavItem,
  topNav: string,
  sideNav: string[] = [],
  parentRoute?: RouteRecordRaw
): RouteRecordRaw | undefined {
  if (!("file" in item) && !("path" in item) && !("route" in item)) {
    return undefined;
  }

  const route: RouteRecordRaw = {
    ...(item.route ?? {}),
    ...(item.path ? { path: item.path } : {}),
    ...(item.file ? { component: item.file } : {}),
  } as RouteRecordRaw;

  if (item?.redirectToParent && parentRoute) {
    const redirectRoute: RouteRecordRaw = {
      path: route.path,
      redirect: {
        path: parentRoute.path,
        ...("redirectHash" in item ? { hash: item.redirectHash as string } : {}),
      },
    };

    if ("name" in route) {
      redirectRoute.name = route.name;
    }

    return redirectRoute;
  }

  return {
    ...route,
    meta: {
      ...route.meta,
      topNav,
      sideNav,
    },
  };
}

export function processSideNav(
  item: DefinedNestedNavItem,
  topNav: string,
  sideNav: string[] = [],
  parentRoute?: RouteRecordRaw
): NestedNavItem {
  const route = createRoute(item, topNav, sideNav, parentRoute);

  const nestedItems = Object.fromEntries(
    Object.entries(item)
      .filter(([k]) => !reservedKeys.includes(k))
      .filter(([, i]) => isItemVisible(i))
      .map(([k, v]) => [k, processSideNav(v, topNav, [...sideNav, k], route)])
      .filter(([, v]) => Object.keys(v).length > 0)
  );

  return route
    ? {
        route,
        ...nestedItems,
      }
    : nestedItems;
}
