const sortAscending = (a: number, b: number) => (a > b ? 1 : -1);

const WHITESPACE_SCORE = -1.1;
const CHAR_SCORE = 1;

/**
 * find the longest ascending group in the number set
 *
 * @param positions
 * @param whitespaces
 * @return range
 */
export const getLongestRangeFromPositions = (
  positions: Set<number>,
  whitespaces: Set<number>
): [number, number] => {
  let lastResult: [number, number] = [-1, -1];
  let lastScore = -1;

  let from = -1;
  let lastPosition = -1;
  let score = 0;

  [...positions].sort(sortAscending).forEach((position) => {
    const isWhitespace = whitespaces.has(position);

    if (from === -1) {
      from = position;
      score = 0;
    } else if (lastPosition + 1 !== position) {
      from = position;
      score = 0;
    }

    score += isWhitespace ? WHITESPACE_SCORE : CHAR_SCORE;

    if (score > lastScore) {
      lastScore = score;
      lastResult = [from, position];
    }

    lastPosition = position;
  });

  return lastResult;
};
