/* eslint-disable prefer-destructuring */

// https://vitejs.dev/guide/env-and-mode.html
/**
 * the mode the app is running in.
 * @see https://vitejs.dev/guide/env-and-mode.html#modes
 */
export const MODE: string = import.meta.env.MODE;

/**
 * the base url the app is being served from. This is determined by the base
 * @see https://vitejs.dev/config/#base
 */
export const BASE_URL: string = import.meta.env.BASE_URL;

/**
 * whether the app is running in production.
 */
export const PROD: boolean = import.meta.env.PROD;

/**
 * whether the app is running in development (always the opposite of import.meta.env.PROD)
 */
export const DEV: boolean = import.meta.env.DEV;

/**
 * whether the app is in ssr mode
 * @see https://vitejs.dev/guide/ssr.html#conditional-logic
 */
export const SSR: boolean = import.meta.env.SSR;
