import { DefinedNestedNavItem } from "./types.js";

export function isItemVisible(item: DefinedNestedNavItem): boolean {
  if (import.meta.env.VITE_BUILD_TARGET === "internal") {
    return true;
  }

  const isNavItem = "file" in item || "path" in item || "route" in item;
  if (!isNavItem) {
    // if it's not an item it is a group
    return true;
  }

  return !!item?.public;
}
