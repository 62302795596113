export enum RouteNames {
  context = "reference-context-",
  overview = "reference-overview",
  profiles = "reference-profiles",
  profileDetail = "reference-profile",
  errors = "reference-errors",
  operation = "reference-operation-",
}

export const routePaths: { [P in RouteNames]: string } = {
  [RouteNames.operation]: "/rest/:id/:operation",
  [RouteNames.context]: "/rest/:context",
  [RouteNames.errors]: "/rest/errors",
  [RouteNames.overview]: "/rest",
  [RouteNames.profiles]: "/profiles/:context?",
  [RouteNames.profileDetail]: "/profiles/:context/:id(.*)",
};
