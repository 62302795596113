<script lang="ts" setup>
import { onMounted, ref } from "vue";
import Icon from "../pattern/Icon/Icon.vue";

const isActive = ref(false);

const checkForAcknowledgement = () => {
  setTimeout(() => {
    isActive.value = true;
  }, 1000);
};

onMounted(() => {
  checkForAcknowledgement();
});

const closeBanner = () => {
  isActive.value = false;
};
</script>

<template>
  <div class="pl_banner pl_banner--info api_portal-banner" :class="{ active: isActive }">
    <slot></slot>
    <Icon icon="pl_icon_close" @click="closeBanner" />
  </div>
</template>

<style lang="scss" scoped>
.api_portal-banner {
  max-width: 30%;
  position: absolute;
  top: 10px;
  right: 0;
  align-items: center;
  z-index: 3;
  transform: translateX(100%);
  transition: transform 0.3s linear;

  &.active {
    transform: translateX(0);
  }
}
</style>
