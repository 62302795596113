import { reactive, ref, toRaw, watch } from "vue";
import { SearchResult } from "../../types/search.js";
import { sendMessage } from "./worker/index.js";

const input = ref("");
const modules = reactive(new Set<string>());
const results = ref<SearchResult[]>([]);

watch([input, modules], async ([query, modules]) => {
  const timestamp = Date.now();
  const searchResult = await sendMessage("search", {
    query,
    timestamp,
    modules: toRaw(modules),
  });

  if (searchResult.status === "CANCELED") {
    // the user typed too fast so we can skip here
    return;
  }

  results.value = searchResult.items;
});

export const useSearch = () => {
  return {
    input,
    modules,
    results,
  };
};
