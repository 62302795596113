<template>
  <svg v-visible-once="() => (visible = true)" :class="`pl_icon${size}`" role="img">
    <use v-if="visible" :xlink:href="href" />
  </svg>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { visibleOnce as vVisibleOnce } from "../../directives/visibility/visibleOnce.js";

withDefaults(
  defineProps<{
    href: string;
    size?: "50" | "100" | "";
  }>(),
  {
    size: "",
  }
);

const visible = ref(false);
</script>
