import type { DefinedNestedNavItem, NestedNavItem } from "./types.js";
import type { RouteRecordRaw } from "vue-router";
import { isItemVisible } from "./isItemVisible.js";

const reservedKeys = ["file", "path", "route", "redirectToParent", "redirectHash", "public"];

export function processTopNav(item: DefinedNestedNavItem, topNav: string): NestedNavItem {
  const nestedItems = Object.fromEntries(
    Object.entries(item)
      .filter(([k]) => !reservedKeys.includes(k))
      .filter(([, i]) => isItemVisible(i))
      .map(([k, v]) => [k, processTopNav(v, k)])
      .filter(([, v]) => Object.keys(v).length > 0)
  );

  if (!isItemVisible(item)) {
    return nestedItems;
  }

  if ("file" in item || "path" in item || "route" in item) {
    const route: RouteRecordRaw = {
      ...(item.route ?? {}),
      ...(item.path ? { path: item.path } : {}),
      ...(item.file ? { component: item.file } : {}),
    } as RouteRecordRaw;

    route.meta = {
      ...route.meta,
      topNav,
    };

    return {
      route,
      ...nestedItems,
    };
  }

  return nestedItems;
}
