import { DEV } from "../../../utils/env/index.js";
import type { executeCommand } from "./commands/index.js";
import sharedWorkerUrl from "./sharedworker.ts?sharedworker&url";
import workerUrl from "./worker.ts?worker&url";

let worker: Worker | SharedWorker;

if (DEV) {
  //Special case to make workers work in serve mode (chrome only)
  worker = new Worker(workerUrl, { type: "module" });
} else if (window.SharedWorker) {
  // inspect and debug via chrome://inspect/#workers
  const sharedWorker = new SharedWorker(sharedWorkerUrl);
  sharedWorker.port.start();
  worker = sharedWorker;
} else {
  // used in serve mode
  // and fallback for safari https://caniuse.com/sharedworkers
  worker = new Worker(workerUrl);
}

export const sendMessage = (async (cmd, data) => {
  return new Promise((resolve, reject) => {
    const messageChannel = new MessageChannel();
    messageChannel.port1.onmessage = ({ data }) => {
      resolve(data);
      messageChannel.port1.close(); // needs to be closed (see: jest --detectOpenHandles)
    };
    messageChannel.port1.onmessageerror = (err) => {
      reject(err);
      messageChannel.port1.close();
    };

    const message = { cmd, data };
    if ("port" in worker) {
      worker.port.postMessage(message, [messageChannel.port2]);
    } else {
      worker.postMessage(message, [messageChannel.port2]);
    }
  });
}) as typeof executeCommand;
