/**
 * @param text
 * @return positions of all whitespaces within given text
 */
export const findAllWhitespaces = (text: string): Set<number> => {
  const result = new Set<number>();
  const regex = /\s/gm;

  let m;
  while ((m = regex.exec(text)) !== null) {
    result.add(m.index);
  }

  return result;
};
