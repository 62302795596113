<template>
  <div
    class="pl_copy100 pl_mb100 schema-description"
    :class="{ 'schema-description--deprecated': deprecated || item.deprecated }"
  >
    <Markdown :markdown="description"></Markdown>

    <SchemaAttribute
      v-for="(attribute, attributeTitle) in attributes"
      :key="attributeTitle"
      :title="attributeTitle"
      :item="attribute"
    >
    </SchemaAttribute>
  </div>
</template>

<script lang="ts" setup>
import { ManifestSchemaItem } from "@otto-ec/ottoapi-cli";
import { Markdown } from "../markdown/index.js";
import { computed } from "vue";
import SchemaAttribute from "./SchemaAttribute.vue";

const props = defineProps<{ item: ManifestSchemaItem; deprecated?: boolean }>();

const description = computed(() => props.item.description ?? "");
const attributes = computed(() => props.item.attributes ?? {});
</script>

<style lang="scss" scoped>
.schema-description--deprecated {
  & * {
    opacity: 0.45;
  }
  .schema-description__tag {
    opacity: 1;
  }
}
</style>
