import { RouteRecordRaw } from "vue-router";

export const devRoutes: RouteRecordRaw[] = [
  {
    path: "/dev",
    component: () => import("../dev/Dev.vue"),
  },
  {
    path: "/dev/search",
    component: () => import("../dev/SearchConfig.vue"),
  },
];

export const routes = import.meta.env.DEV ? devRoutes : [];
