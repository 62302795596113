<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  size?: "25" | "50" | "100";
  color?: "yellow" | "red" | "pink" | "blue";
}>();
const cssBaseClass = "tag";

const size = computed(() => (props.size ? `${cssBaseClass}--size-${props.size}` : ""));
const color = computed(() => (props.color ? `${cssBaseClass}--color-${props.color}` : ""));
</script>

<template>
  <span class="tag" :class="[size, color]"> <slot></slot> </span>
</template>

<style lang="scss">
.tag {
  position: relative;
  display: inline-block;
  color: var(--base-black);
  font-weight: 700;
  line-height: 1.5em;
  padding: 0 0.5em;
  justify-content: center;
  border-radius: 2px;
  background: var(--dark-sunny-yellow);

  &--color-yellow {
    background-color: var(--dark-sunny-yellow);
  }

  &--color-red {
    background-color: var(--bright-coral);
  }

  &--color-pink {
    background-color: var(--cozy-pink);
  }

  &--color-blue {
    color: var(--base-white);
    background-color: var(--dark-denim);
  }

  &--size-25 {
    font-size: 10px;
  }

  &--size-50 {
    font-size: 12px;
  }

  &--size-100 {
    font-size: 14px;
  }
}
</style>
