<template>
  <div
    class="schema-header pl_copy100"
    :class="{ 'schema-header--deprecated': deprecated || item.deprecated }"
  >
    <span class="schema-header__title">{{ title }}</span>
    <span class="schema-header__type">{{ itemType }}</span>
    <span v-if="item.additionalProperty" class="pl_copy100 schema-header__additional-property">
      additional property
    </span>
    <TagStatus v-if="item.deprecated" size="50" color="yellow">deprecated</TagStatus>
    <TagStatus v-if="item.preview" size="50" color="blue">preview</TagStatus>
    <span v-if="item.required" class="pl_copy100 schema-header__required">required</span>
  </div>
</template>

<script lang="ts" setup>
import { ManifestSchemaItem } from "@otto-ec/ottoapi-cli";
import { computed } from "vue";
import TagStatus from "../pattern/TagStatus/TagStatus.vue";

const props = defineProps<{
  item: ManifestSchemaItem;
  title: string;
  deprecated?: boolean;
}>();

const itemType = computed(() => {
  if (props.item.type === "array") {
    const arrayType = props.item.children?.items?.type;
    return `array of ${arrayType}`;
  }

  return props.item.type;
});
</script>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.schema-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 6px;

  &--deprecated {
    .schema-header__title,
    .schema-header__required,
    .schema-header__type,
    .schema-header__additional-property {
      opacity: 0.45;
    }
  }

  &__required {
    color: gfs.$pl_color-red100;
  }

  &__type {
    color: gfs.$pl_color-grey400;
  }

  &__additional-property {
    color: var(--bright-warm-red);
  }
}
</style>
