<template>
  <span class="markdown-icon">
    <Icon :icon="plIcon" :size="data ?? '100'"></Icon>
  </span>
</template>

<script lang="ts" setup>
import { useSlots } from "vue";
import { Icon, IconPropType } from "../../index.js";
import { assert } from "../../../utils/assert.js";

const { default: slot } = useSlots();
const slotIcon = slot ? slot()[0].children : "";

assert(typeof slotIcon === "string", "invalid icon syntax");

const plIcon: IconPropType = (
  slotIcon.startsWith("pl_icon_") ? slotIcon : "pl_icon_" + slotIcon
) as IconPropType;

defineProps<{
  data?: "50" | "100";
}>();
</script>

<style lang="scss">
.markdown-icon {
  vertical-align: middle;
}
</style>
