import { RouterScrollBehavior } from "vue-router";

const SCROLL_CONTAINER_ID = "#scroll-container";
export const scrollBehavior: RouterScrollBehavior = async (to) => {
  if (to.hash) {
    const elementWithHashID = document.querySelector(to.hash);
    if (elementWithHashID) {
      // wait for routed view to get loaded
      setTimeout(() => {
        elementWithHashID.scrollIntoView();
        return;
      }, 500);
    }
  } else {
    const contentElement = document.querySelector(SCROLL_CONTAINER_ID);
    if (contentElement) {
      contentElement.scroll(0, 0);
    }
  }
};
