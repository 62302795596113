import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { Directive } from "vue";

/**
 * Prevents scrolling on the body inside an overlay
 * example:
 * <Frame v-body-scroll-lock="isOpen" v-show="isOpen">...</Frame>
 */
export const vBodyScrollLock: Directive = {
  mounted: (el, binding) => {
    if (binding.value) {
      disableBodyScroll(el);
    }
  },
  updated: (el, binding) => {
    if (binding.oldValue === binding.value) {
      return;
    }

    if (binding.value) {
      disableBodyScroll(el);
    } else {
      enableBodyScroll(el);
    }
  },
  unmounted: (el) => {
    enableBodyScroll(el);
  },
};
