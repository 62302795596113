<script lang="ts" setup>
import { useClipboard } from "@vueuse/core";
import { SvgUse, useCanHover, useRoute } from "../../index.js";
import { chainIcon } from "../../assets/icons/index.js";
import { computed } from "vue";
import Tooltip from "../Tooltip/Tooltip.vue";

const props = defineProps<{
  anchorId: string;
  anchorPath?: string;
}>();

const { base, pathname } = useRoute();

const permalinkUrl = computed(() => {
  if (props.anchorPath) {
    const anchorPath = props.anchorPath.startsWith("/")
      ? props.anchorPath.substring(1)
      : props.anchorPath;

    return `${base.value}${anchorPath}`;
  }

  return `${pathname.value}#${props.anchorId}`;
});

const permalinkTitle = computed(() => {
  return props.anchorPath ? "Copy permalink to clipboard" : "Copy anchor link to clipboard";
});

const { canHover } = useCanHover();
const { copy, copied } = useClipboard({ copiedDuring: 1500 });
</script>

<template>
  <span class="permalink">
    <span
      class="permalink-symbol"
      :class="{ canHover: canHover }"
      :title="permalinkTitle"
      @click="copy(permalinkUrl)"
    >
      <SvgUse v-if="anchorPath" :href="chainIcon"></SvgUse>
      <span v-else>#</span>
    </span>

    <Tooltip :active="copied" tooltip-text="Copied!" />
  </span>
</template>

<style lang="scss">
@use "@otto-ec/global-frontend-settings/gfs";

//used outside
.permalink-parent {
  &:hover {
    .permalink-symbol {
      opacity: 0.4;
    }
  }
}

.permalink-symbol {
  color: gfs.$pl_color-red100;
  user-select: none;
  transition: opacity 0.3s ease;

  &.canHover {
    opacity: 0;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  svg {
    fill: gfs.$pl_color-red100;
    height: 18px;
    width: 18px;
  }
}
</style>
