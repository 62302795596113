import type { SideNavConfig, TopNavConfig } from "./index.js";

export let definedSideNav: Readonly<SideNavConfig> = {};
export const setDefinedSideNav = (v: SideNavConfig) => {
  definedSideNav = v;
};

export let definedTopNav: Readonly<TopNavConfig> = {};
export const setDefinedTopNav = (v: TopNavConfig) => {
  definedTopNav = v;
};
