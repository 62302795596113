<template>
  <div class="main">
    <div v-if="mdAndUp" class="main__item1">
      <Navigation />
    </div>
    <div id="scroll-container" class="main__item2">
      <div class="main__item3">
        <div class="breadcrumb-headline">
          <Breadcrumb :items="breadcrumb"></Breadcrumb>
        </div>
        <slot></slot>
      </div>
      <div class="main__item4">
        <PortalFooter></PortalFooter>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Navigation from "../components/Navigation/Navigation.vue";
import PortalFooter from "../components/footer/PortalFooter.vue";
import { Breadcrumb } from "../components/Breadcrumb/index.js";
import { useBreadcrumb } from "../use/navigation/breadcrumb.js";
import { useMediaQuery } from "../use/index.js";

const { breadcrumb } = useBreadcrumb();
const { mdAndUp } = useMediaQuery();
</script>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";
@use "../scss/variables";

.main {
  height: 100%;
  max-width: variables.$max-width;
  display: flex;
  margin: auto;
  gap: 4px;

  &__item1 {
    height: 100%;
    overflow: auto;
    flex: 1;
  }

  &__item2 {
    height: 100%;
    overflow: auto;
    flex: 4;
    display: grid;
    grid-template-rows: auto 200px;
  }

  &__item3 {
    min-width: 0; //override min-width: auto from grid
  }

  &__item4 {
    justify-content: center;
    align-self: center;
  }
}

.breadcrumb-headline {
  margin-bottom: gfs.$pl_margin25;
  background-color: gfs.$pl_color-white100;
  padding: 0 8px;
}
</style>
