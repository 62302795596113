import "./scss/main.scss";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ApmVuePlugin } from "@elastic/apm-rum-vue";

import { createApp } from "vue";

import App from "./App.vue";

import * as markdownComponents from "./components/markdown/index.js";
import { defaultRouter } from "./router/index.js";
import { MODE, PROD, VITE_CONFIG_PERFORMANCE } from "./utils/index.js";
import { PortalModule } from "./types/index.js";
import { setDefinedSideNav, setDefinedTopNav } from "./navigation/index.js";
import { SideNavConfig, TopNavConfig } from "./navigation/index.js";
import { Accordion, AccordionContent, AccordionHeader } from "./components/index.js";

export * from "./components/index.js";
export * from "./navigation/index.js";
export * from "./router/index.js";
export * from "./types/index.js";
export * from "./use/index.js";
export * from "./utils/index.js";

/**
 * Creates default vue portal app out of Root module and Portal Modules
 *
 * @param options creator options
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createDefaultApp({
  modules,
  definedSideNav,
  definedTopNav,
}: {
  /** List of modules to be registered with portal app */
  modules: PortalModule[];

  definedSideNav: SideNavConfig;
  definedTopNav: TopNavConfig;
}) {
  setDefinedSideNav(definedSideNav);
  setDefinedTopNav(definedTopNav);

  const router = defaultRouter(...modules);

  const app = createApp(App).use(router);

  if (PROD && MODE !== "test") {
    app.use(ApmVuePlugin, {
      router,
      config: {
        serviceName:
          import.meta.env.VITE_BUILD_TARGET === "internal" ? "internal-api-portal" : "api-portal",
        serverUrl: import.meta.env.VITE_APM_TRACKING_URL,
        environment: import.meta.env.VITE_TB_ENVIRONMENT,
      },
    });
  }

  Object.entries(markdownComponents).forEach(([name, component]) => app.component(name, component));
  app.component("Accordion", Accordion);
  app.component("AccordionHeader", AccordionHeader);
  app.component("AccordionContent", AccordionContent);

  if (VITE_CONFIG_PERFORMANCE) {
    app.config.performance = true;
  }

  return app.mount("#app");
}

export default createDefaultApp;
