<script lang="ts" setup>
import { CodeExpander, PrismCode } from "../../PrismCode/index.js";
import { computed } from "vue";

// show text expander if code has more than 15 lines
const MIN_CODE_LINES_FOR_TEXT_EXPANDER = 15;

const props = defineProps<{
  language: string;
  content: string;
}>();

const numberOfLines = computed(() => (props.content.match(/\n/g) ?? []).length);

const withTextExpander = computed(() => numberOfLines.value > MIN_CODE_LINES_FOR_TEXT_EXPANDER);
</script>

<template>
  <CodeExpander v-if="withTextExpander">
    <PrismCode :code="content" :language="language"></PrismCode>
  </CodeExpander>
  <div v-else class="pl_mb50">
    <PrismCode :code="content" :language="language"></PrismCode>
  </div>
</template>
