import { RouteNames } from "../router/index.js";
import type { DefinedNestedNavItem } from "@otto-ec/ottoapi-portal-common";

export const contact: DefinedNestedNavItem = {
  route: {
    path: `/${RouteNames.contact}`,
    name: RouteNames.contact,
  },
};

export const faq: DefinedNestedNavItem = {
  route: {
    path: `/${RouteNames.faq}`,
    name: RouteNames.faq,
  },
};

export const support: DefinedNestedNavItem = {
  route: {
    path: "/support",
    name: RouteNames.support,
    component: () => import("../views/Support.vue"),
    meta: { layout: "FullLayout" },
  },
};
