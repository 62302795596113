<script lang="ts" setup>
import { ref } from "vue";
import { Icon } from "../pattern/index.js";

const isExpanded = ref(false);
const isBlurred = ref(true);
const isRotated = ref(false);
</script>

<template>
  <div class="code-expander" :class="{ 'code-expander--expanded': isExpanded, blurred: isBlurred }">
    <slot></slot>
  </div>
  <div
    class="pl_link100--primary pl_px100 pl_py75 code-expander__link"
    :class="{ 'code-expander-ready2hide': isExpanded }"
    @click="
      isExpanded = !isExpanded;
      isBlurred = !isBlurred;
      isRotated = !isRotated;
    "
  >
    <Icon
      icon="pl_icon_arrow-down"
      size="100"
      class="code-expander-icon"
      :class="{ rotated: isRotated }"
    ></Icon>
    {{ !isExpanded ? "Show more" : "Hide" }}
  </div>
</template>

<style lang="scss" scoped>
.code-expander {
  height: 140px;
  min-height: 140px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.code-expander.blurred {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  &:after {
    content: "";
    height: 64px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    display: block;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.01) 0%, #f0f0f0 100%);
  }
}

.code-expander--expanded {
  height: auto;
}

.code-expander__link {
  display: flex;
  gap: 16px;
}

.code-expander-icon.rotated {
  transform: rotate(180deg);
}
</style>
