<template>
  <em>
    <slot></slot>
  </em>
</template>

<style lang="scss" scoped>
em {
  font-style: italic;
}
</style>
