<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { Icon } from "../pattern/Icon/index.js";
import PortalHeaderLogo from "../header/PortalHeaderLogo.vue";
import { useTopNav } from "../../use/navigation/useTopNav.js";
import NavigationMobile from "../Navigation/NavigationMobile.vue";
import { RouteRecordRaw } from "vue-router";

const { topNav, activeEntry } = useTopNav();

const emit = defineEmits(["update:modelValue", "closeDrawer"]);

const isActive = ref(false);

onMounted(async () => {
  openDrawer();
});

const openDrawer = () => {
  isActive.value = true;
};

const closeDrawer = () => {
  isActive.value = false;
  setTimeout(() => {
    emit("closeDrawer");
  }, 300);
};
</script>

<template>
  <div class="drawer" :class="{ 'drawer--active': isActive }">
    <div class="drawer__header">
      <PortalHeaderLogo />
      <div class="pl_rounded50">
        <Icon
          data-close-icon
          class="drawer__close-icon"
          icon="pl_icon_close"
          size="100"
          @click="closeDrawer()"
        />
      </div>
    </div>
    <div class="drawer__content">
      <div class="pl_block--no-padding drawer__content-chips">
        <template v-for="(item, title) in topNav" :key="title">
          <router-link
            v-if="'route' in item"
            :to="'route' in item ? item.route as RouteRecordRaw : ''"
            class="pl_link100--secondary"
            role="navigation"
          >
            <a
              class="pl_chip200 pl_chip100--grey drawer__content-chip"
              :class="{ 'drawer__content-chip--active': activeEntry === title }"
              >{{ title }}</a
            >
          </router-link>
        </template>
      </div>

      <div class="pl_block--no-padding pl_mt25 drawer__navigation">
        <NavigationMobile @close-drawer="closeDrawer" />
      </div>
    </div>
  </div>
  <div
    data-backdrop
    class="drawer__backdrop"
    :class="{ 'drawer__backdrop--hidden': !isActive }"
    @click="closeDrawer()"
  ></div>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.drawer {
  max-width: 342px;
  width: 100%;
  position: fixed;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  transform: translateX(-100%);
  inset: 0;
  z-index: 3;
  animation: drawer-animation-open 0.4s;
  transition: transform 0.4s ease;

  @keyframes drawer-animation-open {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  &--active {
    transform: translateX(0);
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: gfs.$pl_color-white100;
  }

  &__close-icon {
    fill: gfs.$pl_color-red100;
    width: 32px;
    height: 32px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: gfs.$pl_color-white100;
    bottom: 0;
    top: 62px;
    max-width: 384px;
    width: 100%;
  }

  &__content-chips {
    display: flex;
    justify-content: space-evenly;
  }

  &__content-chip {
    display: flex;
    align-items: center;
    height: 32px;
    position: relative;
    font-size: 12px;
  }

  &__content-chip--active {
    height: 32px;
    background-color: #b1ddff;
    font-weight: bold;
    color: gfs.$pl_color-black100;
  }

  &__navigation {
    overflow: auto;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 0.5);
    transition: opacity 0.3s ease;
    z-index: 2;

    &--hidden {
      opacity: 0;
      transition: opacity ease 0.3s;
    }
  }
}
</style>
