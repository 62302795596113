<script lang="ts" setup>
import { computed } from "vue";
import { ManifestSchemaItem } from "@otto-ec/ottoapi-cli";
import SchemaEntryAnyOrOneOf from "./SchemaEntryAnyOrOneOf.vue";
import SchemaArrayItemEntry from "./SchemaArrayItemEntry.vue";
import SchemaDescription from "./SchemaDescription.vue";
import SchemaHeader from "./SchemaHeader.vue";
import { getChildren } from "./getChildren.js";
import { getChildrenOfArray } from "./getChildrenOfArray.js";
import Accordion from "../Accordion/Accordion.vue";
import AccordionContent from "../Accordion/AccordionContent.vue";
import AccordionHeader from "../Accordion/AccordionHeader.vue";

const props = defineProps<{
  item: ManifestSchemaItem;
  title: string;
  deprecated?: boolean;
  preview?: boolean;
}>();

const children = computed(() => getChildren(props.item));

const isChildAnyOrOneOf = computed(() => "ANY OF" in children.value || "ONE OF" in children.value);

const childrenOfArray = computed(() => getChildrenOfArray(props.item));
</script>

<template>
  <SchemaEntryAnyOrOneOf
    v-if="isChildAnyOrOneOf"
    :item="item"
    :title="title"
    :deprecated="deprecated || !!item.deprecated"
    :preview="preview || !!item.preview"
  ></SchemaEntryAnyOrOneOf>

  <Accordion v-else>
    <AccordionHeader>
      <SchemaHeader
        :item="item"
        :title="title"
        :deprecated="deprecated"
        :preview="preview"
      ></SchemaHeader>
    </AccordionHeader>
    <AccordionContent>
      <SchemaDescription :item="item" :deprecated="deprecated"></SchemaDescription>
      <SchemaArrayItemEntry
        v-if="childrenOfArray"
        :item="childrenOfArray.items"
        :deprecated="deprecated || !!item.deprecated"
        :preview="preview || !!item.preview"
      ></SchemaArrayItemEntry>

      <SchemaEntry
        v-for="(child, childTitle) in children"
        :key="childTitle"
        :title="childTitle as string"
        :item="child"
        :deprecated="deprecated || !!item.deprecated"
        :preview="preview || !!item.preview"
        class="pl_ml150"
      ></SchemaEntry>
    </AccordionContent>
  </Accordion>
</template>
