<script lang="ts" setup>
import type Fuse from "fuse.js";
import { computed } from "vue";
import { useSearch } from "../../use/search/index.js";
import { findLongestIndex } from "./highlight/findLongestIndex.js";

const props = defineProps<{
  value: string;
  indices: Fuse.default.FuseResultMatch["indices"];
  isHeadline?: true;
}>();

const { input } = useSearch();

const index = computed(() => findLongestIndex(props.indices, props.value, input.value));

const MAX_STRING_LENGTH_BEFORE = 50;
const MAX_STRING_LENGTH_AFTER = 50;

const ellipsesBefore = (index: number) => {
  return index - MAX_STRING_LENGTH_BEFORE > 0 ? " [...] " : "";
};

const ellipsesAfter = (index: number) => {
  const remainingCharsAfterMarker = props.value.length - index;
  return remainingCharsAfterMarker > MAX_STRING_LENGTH_AFTER ? " [...] " : "";
};

const pre = computed(() => {
  if (!index.value) {
    return "";
  }

  if (!props.isHeadline) {
    return `${ellipsesBefore(index.value[0])}${props.value.substring(
      index.value[0] - MAX_STRING_LENGTH_BEFORE,
      index.value[0]
    )}`;
  }

  return props.value.substring(0, index.value[0]);
});

const mark = computed(() => {
  if (!index.value) {
    return "";
  }

  return props.value.substring(index.value[0], index.value[1] + 1);
});

const post = computed(() => {
  if (!index.value) {
    return "";
  }

  if (!props.isHeadline) {
    return ` ${props.value.substring(
      index.value[1] + 1,
      index.value[1] + MAX_STRING_LENGTH_AFTER
    )} ${ellipsesAfter(index.value[1])}`;
  }

  return props.value.substring(index.value[1] + 1);
});
</script>

<template>
  {{ pre }}<mark>{{ mark }}</mark
  ><template v-if="post">{{ post }}</template>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

mark {
  background-color: transparentize(gfs.$pl_color-blue100, 0.8);
}
</style>
