<script lang="ts" setup>
import { computed } from "vue";
import { TableLink } from "../../pattern/index.js";

const props = defineProps<{
  ruleId: string;
}>();

const parsedHref = computed(() => `/guidelines/${props.ruleId.toLowerCase()}`);
</script>

<template>
  <TableLink :to="parsedHref">
    <div>
      <slot></slot>
    </div>
  </TableLink>
</template>

<style lang="scss">
.guidelines-must,
.guidelines-should,
.guidelines-may {
  font-weight: bold;

  + p {
    display: inline;
  }
}
</style>
