import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
  RouteRecordRaw,
} from "vue-router";
import { BASE_URL } from "../utils/index.js";
import type { PortalModule } from "../types/index.js";
import { scrollBehavior } from "./scrollBehavior.js";
import { definedSideNav, definedTopNav, isNavItem, NestedNavItem } from "../navigation/index.js";
import { routes } from "./routes.js";

const AppTitle = "OTTO Consumer API";

/**
 * Catch all routes are used for error handling
 */
export const catchAllRoutes = [
  {
    path: "/error",
    name: "error",
    component: () => import("../views/Error.vue"),
    meta: { title: "Error", layout: "FullLayout" },
  },
  { path: "/:pathMatch(.*)*", redirect: "/error" },
] as RouteRecordRaw[];

/**
 * Updates Page title for every route
 * @param to
 * @param from
 * @param next
 */
export function pageTitleGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void {
  document.title = (to.meta?.topNav ? to.meta.topNav + " - " : "") + AppTitle;
  next();
}

const addRoutes = (router: Router, item: NestedNavItem) => {
  if (isNavItem(item)) {
    router.addRoute(item.route);
  }
  Object.entries(item)
    .filter(([k]) => k !== "route")
    .forEach(([, v]) => addRoutes(router, v));
};

/**
 * Called internally by the App Creator function
 * @param modules
 * @returns
 */
export function defaultRouter(...modules: PortalModule[]): Router {
  const router = createRouter({
    scrollBehavior,
    history: createWebHistory(BASE_URL),
    routes,
  });

  addRoutes(router, definedSideNav);
  addRoutes(router, definedTopNav);

  modules.forEach(({ routes }) => {
    routes.forEach((r) => router.addRoute(r));
  });

  // ? Catch all routes must be at the end of everything
  catchAllRoutes.forEach((r) => router.addRoute(r));

  router.beforeEach(pageTitleGuard);

  return router;
}
