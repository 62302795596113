<template>
  <PortalHeaderMobile v-if="widthAndDown" />
  <PortalHeaderDesktop v-else />
</template>

<script lang="ts" setup>
import PortalHeaderMobile from "./PortalHeaderMobile.vue";
import PortalHeaderDesktop from "./PortalHeaderDesktop.vue";
import { useMediaQuery } from "../../use/index.js";

const { widthAndDown } = useMediaQuery("860px");
</script>
